<script setup lang="ts"></script>

<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
  >
    <circle
      class="path circle animate-[loading_0.9s_linear_infinite] stroke-primary"
      fill="none"
      stroke-width="6"
      stroke-miterlimit="10"
      stroke-dasharray="200"
      stroke-dashoffset="0"
      cx="65.1"
      cy="65.1"
      r="62.1"
    />
  </svg>
</template>

<style></style>
